import React, { useState, useEffect } from "react";
import styles from "./CountDown.module.css";

const getTimeLeft = (startTime) => {
  const COUNTDOWN_TARGET = new Date(startTime);
  const totalTimeLeft = COUNTDOWN_TARGET - new Date();
  const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((totalTimeLeft / 1000) % 60);
  return { days, hours, minutes, seconds };
};

export const CountDown = ({ startTime, contentfulStrings }) => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft(startTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(startTime));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.countdown}>
      {Object.entries(timeLeft).map((el) => {
        const label = el[0];
        const value = el[1];
        return (
          <div className={styles.box} key={label}>
            <div className={styles.value}>
              <span>{value}</span>
            </div>
            <span className={styles.label}>
              {contentfulStrings(`${label}`)}
            </span>
          </div>
        );
      })}
    </div>
  );
};
