import { PROD, STAG } from "../constants/constants";

function getQueryParams(url) {
  const queryParams = {};
  const queryString = url.split("?")[1];
  if (queryString) {
    const pairs = queryString.split("&");
    pairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      queryParams[key] = value;
    });
  }
  return queryParams;
}
const queryParams = getQueryParams(window.location.href);

export const getEnv = () => {
  if (
    window.location.href.includes("prod") ||
    (queryParams.hasOwnProperty("env") && queryParams["env"] === "prod")
  ) {
    return PROD;
  }
  return STAG;
};

export const getArticleId = () => {
  const searchParams = new URLSearchParams(window?.location?.search);
  let articleId;

  if (searchParams?.get("articleId")?.includes("ArticleId")) {
    articleId = searchParams?.get("articleId")?.split(":")[1];
  } else {
    articleId = searchParams?.get("articleId");
  }

  return articleId;
};

export const addArticleIdToApiUrl = (url) => {
  const articleId = getArticleId();
  const newUrl = new URL(url);
  newUrl.searchParams.append("articleId", articleId);
  return newUrl.href;
};

export const betSlipConfig = {
  AmountsToBeShown: [2, 5, 10, 20],
  fixtureId: getEnv() === PROD ? "dygf9n0u0hm306qa35jjej75v" : "fixtureId12",
  PotentialReturnBetAmount: 10,
  maxStakeAllowed: 50,
  minBetAllowed: 0.5,
  isOddsEnabled: false,
};

export const partnerServices = {
  prod: "https://partner-outbound-integration.ar.indazn.com/v1",
  stage: "https://sports-betting-service.picks.dazn-stage.com/v1",
};

export const stageOauthConfig = {
  api_params:
    '[{"clientName":"dazn_bet","clientId":"dazn-bet","redirectUri":"https://uat-daznbet-uk.uatsecure.com/en-gb?linkType=DAZN_IN_APP","notLoggedinUrl":"https://uat-daznbet-uk.uatsecure.com/en-gb?linkType=DAZN_IN_APP","utmCampaign":"referral_sports_uk_dazn_daznbet_none_dmoreinfo"}]',
  error_code_mappings:
    '{"100":"100","102":"102","103":"103","105":"105","850":"850","860":"860"}',
  utm_params:
    '[{"utm_source":"referral"},{"utm_medium":"dazn"},{"utm_campaign":"referral_sports_default_other_daznbet_none"}]',
};

export const prodOauthConfig = {
  api_params:
    '[{"clientName":"dazn_bet","clientId":"dazn-bet","redirectUri":"https://www.daznbet.com/en-gb?linkType=DAZN_IN_APP","notLoggedinUrl":"https://www.daznbet.com/en-gb?linkType=DAZN_IN_APP","utmCampaign":"referral_sports_uk_dazn_daznbet_none_dmoreinfo"}]',
  error_code_mappings:
    '{"100":"100","102":"102","103":"103","105":"105","850":"850","860":"860"}',
  utm_params:
    '[{"utm_source":"referral"},{"utm_medium":"dazn"},{"utm_campaign":"referral_sports_default_other_daznbet_none"}]',
};

export const oauthConfig =
  getEnv() === PROD ? prodOauthConfig : stageOauthConfig;
