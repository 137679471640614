import React from "react"; // Add the missing import statement for React

import { Provider } from "mobx-react";
import BetStore from "./stores/bet.store";
import AppComponent from "./components/AppComponent";

const store = BetStore.create();

export const App = () => (
  <Provider betStore={store}>
    <AppComponent />
  </Provider>
);