import { betSlipConfig, oauthConfig } from "./betslip.config";
import { endpointUrls, methodType } from "../constants/api.constants";
import { getAuthToken } from "./common.utils";

const ssoFunction = ( betRedirectionUri) => {
  const OauthParams = JSON.parse(oauthConfig.api_params);
  const daznBetParams = OauthParams.find((x) => x.clientName === "dazn_bet");
  const { clientId, scope } = daznBetParams;
  let { redirectUri } = daznBetParams;
  redirectUri = betRedirectionUri;

  const jwtToken = getAuthToken();
  return new Promise((resolve, reject) => {
    const redirectTo = new URL(`${redirectUri}`);
    fetch(endpointUrls.OAUTH, {
      method: methodType.POST,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: clientId,
        redirect_uri: redirectUri,
        scopes: scope ?? [],
      }),
    })
      .then((response) => response.json())
      .then(({ url }) => {
        // redirectTo.searchParams.append('code', code);
        resolve(url);
      })
      .catch(({ response }) => {
        const { code, error } = response?.data;
        redirectTo.searchParams.append("error_code", code ? code : error);
        reject(redirectTo.href);
      });
  });
};
const getBetSlipEventIdAndAmounts = () => {
  try {
    const fixtureId = betSlipConfig.fixtureId;
    const AmountsToBeShown = betSlipConfig.AmountsToBeShown;
    return { AmountsToBeShown, fixtureId };
  } catch (e) {
    return false;
  }
};

const isBetSlipEnabledFeatureFlag = () => {
  return true;
};

const getPotentialBetAmount = () => {
  try {
    return betSlipConfig.PotentialReturnBetAmount;
  } catch (e) {
    return 10;
  }
};

const getMaxStakeAllowed = () => {
  try {
    return betSlipConfig.maxStakeAllowed;
  } catch (e) {
    return 50;
  }
};

const getMinBetAllowed = () => {
  try {
    return betSlipConfig.minBetAllowed;
  } catch (e) {
    return 0.5;
  }
};

const getCurrencySymbol = (currencyCode, locale = "en-GB") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
  });
  const parts = formatter.formatToParts(1);
  const currencyPart = parts.find((part) => part.type === "currency");
  return currencyPart ? currencyPart.value : null;
};

const betSlipConfigs = getBetSlipEventIdAndAmounts();
const isBetSlipEnabled = isBetSlipEnabledFeatureFlag();
const maxStakeAllowed = getMaxStakeAllowed();
const minBetAllowed = getMinBetAllowed();

export {
  ssoFunction,
  betSlipConfigs,
  isBetSlipEnabled,
  getPotentialBetAmount,
  maxStakeAllowed,
  getCurrencySymbol,
  minBetAllowed,
};
