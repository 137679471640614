import { Player } from "@lottiefiles/react-lottie-player";
import styles from "./styles/BetSlip.module.css";
import MarkdownContent from "../MarkdownContent/MarkdownContent";
import { betCcbEvents, countryCodes } from "../../constants/betslip.constants";
import { messageToNative } from "../../services/nativeApp.service";
import { getCountryCode } from "../../utils/common.utils";
import classNames from "classnames";

export const FixtureDetailsScreen = ({ fixtureDetails }) => {
  if (!fixtureDetails) return null;

  const title = fixtureDetails.Title;
  const sportTitle = fixtureDetails.Competition?.Title;
  const startTime = new Date(fixtureDetails.Start);
  const day = startTime.getDate();
  const month = startTime.getMonth();
  const hours = startTime.getHours();
  const minutes = startTime.getMinutes();

  return (
    <div className={styles.fixtureDetails}>
      {title && <h3 className={styles.fixtureTitle}>{title}</h3>}
      {startTime && (
        <div className={styles.fixtureBody}>
          {sportTitle && <p className={styles.fixtureTime}>{sportTitle}</p>}
          {" | "}
          {day && month && (
            <p className={styles.fixtureTime}>
              {day}
              {"/"}
              {month}
            </p>
          )}
          {" | "}
          <p className={styles.fixtureTime}>
            {hours ? hours : "00"}:{minutes ? minutes : "00"}
          </p>
        </div>
      )}
    </div>
  );
};

export const FreeBet = ({ contentfulStrings, animationData }) => {
  const countryCode = getCountryCode();
  const handleFreeBetTerms = async () => {
    messageToNative(betCcbEvents.DAZN_BET, {
      isExternal: true,
      url: contentfulStrings("freeBetTermsUrl"),
    });
  };

  return (
    <div
      className={classNames(styles.freeBetSection, {
        [styles.dynamicFreeBetSection]: countryCodes.GERMANY === countryCode,
      })}
    >
      <div className={styles.freeBetDiv}>
        <MarkdownContent className={styles.freeBetText}>
          {contentfulStrings("freeBetText")}
        </MarkdownContent>
        {animationData && (
          <Player
            autoplay
            loop={true}
            src={animationData}
            className={styles.freeBetGif}
          />
        )}
      </div>
      <div
        className={styles.freeBetTermsAndConditions}
        onClick={handleFreeBetTerms}
      >
        {contentfulStrings("freeBetTermsAndConditions")}
      </div>
    </div>
  );
};
