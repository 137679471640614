import { useState, useEffect } from "react";
import { contentfulService } from "../../services/contentful.service";
import { getCountryCode } from "../../utils/common.utils";
import { oddsCheckerDataProxy } from "./oddsCheckerData.proxy";

const ODDS_CHECKER = "CatalogueOddsChecker";

function useOddsCheckerData() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await contentfulService(ODDS_CHECKER, {
          "fields.tags[includes]": getCountryCode(),
        });
        if (response !== null) {
          const result = response?.items
            ?.map((item) => oddsCheckerDataProxy(item))
            ?.sort((a, b) => a.tag - b.tag);
          setData(result);
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return { data, isLoading, error };
}

export { useOddsCheckerData };
