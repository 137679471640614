import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../utils/textTransforms";

import styles from "./styles/Question.module.css";
function Button({ option, onClick, isActive }) {
  const buttonClick = () => {
    onClick(option);
  };

  return (
    <div
      onClick={buttonClick}
      className={classNames({
        [styles.button]: true,
        [styles.isBlack]: isActive,
      })}
    >
      {capitalizeFirstLetter(option.displayName)}
    </div>
  );
}

Button.propTypes = {
  isActive: PropTypes.bool.isRequired,
  option: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;
