import curry from 'ramda/src/curry';

/**
 * Escape string
 *
 * Escape strings so they can safely be used with regex
 * Example: string `--` would change to `\-\-`
 *
 * @param {String} str - string that needs escaping
 *
 * @returns {String}
 */
const escapeString = str =>
    // eslint-disable-next-line no-useless-escape
    str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

const constructMultiFindRegex = findItems => new RegExp(findItems.join('|'), 'gi');

const constructPatternKeyMap = (keyMappings, patternStart, patternEnd) =>
    Object.keys(keyMappings).reduce((result, key) => {
        const safeKey = escapeString(`${patternStart}${key}${patternEnd}`);
        result[safeKey] = keyMappings[key];
        return result;
    }, {});

const validPattern = pattern => typeof pattern === 'string' && pattern.length > 0;

const stringByPatternReplacer = (patternStart, patternEnd, keyMappings, input) => {
    if (!validPattern(patternStart) || !validPattern(patternEnd)) {
        return input;
    }
    if (!Object.keys(keyMappings).length) {
        return input;
    }
    const patternKeyMappings = constructPatternKeyMap(
        keyMappings,
        patternStart,
        patternEnd
    );
    const regex = constructMultiFindRegex(Object.keys(patternKeyMappings));
    const replaceMatchedValue = matchedValue => {
        const escapedValue = escapeString(matchedValue);
        return patternKeyMappings[escapedValue];
    };

    if (input && typeof input.replace === 'function') {
        return input.replace(regex, replaceMatchedValue);
    }

    return input;
};

const replaceStringWithPattern = curry(stringByPatternReplacer)('%{', '}');

export {
    replaceStringWithPattern,
    stringByPatternReplacer,
    constructMultiFindRegex,
    constructPatternKeyMap,
    escapeString,
    validPattern,
};
