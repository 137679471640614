import React from "react";

import Button from "./Button";
import styles from "./styles/Question.module.css";
import classNames from "classnames";
import { betCcbEvents } from "../../constants/betslip.constants";
import { messageToNative } from "../../services/nativeApp.service";

export function Question({ data, betStore }) {
  const { addAnswer, isOptionAnswered } = betStore;
  return (
    <div className={classNames(styles.main)}>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.question)}> {data.displayName}</div>
        <div className={classNames(styles.buttonsDiv)}>
          {data?.options?.map((option) => (
            <Button
              key={option?.id}
              onClick={(option) => {
                // Analytics
                messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
                  analyticEvent: `optionClick-${option?.displayName}`,
                });

                addAnswer({
                  id: data.id,
                  selection: {
                    id: option.id,
                    // displayName: option.displayName,
                  },
                });
              }}
              option={option}
              isActive={isOptionAnswered(data.id, option.id?.toString())}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
