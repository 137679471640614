import React, { useEffect, useState, useRef } from "react";
import { getContentfulComponent } from "../../../utils/common.utils";
import styles from "./SlimBreathers.module.css";
import classNames from "classnames";
import { betCcbEvents } from "../../../constants/betslip.constants";
import { messageToNative } from "../../../services/nativeApp.service";
import MarkdownContent from "../../MarkdownContent/MarkdownContent";
import { breatherProxy } from "./BreatherProxy";
import { ssoFunction } from "../../../utils/betslip.utils";
import { Spin } from "../reusable/svgUtils";

const ThemeTypes = {
  LIGHT: "Light",
  DARK: "Dark",
};

function SlimBreathers({ contentfulData }) {
  const breathers = getContentfulComponent(contentfulData, "slimBreatherIc");

  if (breathers.length === 0) return null;
  return (
    <div className={styles.breatherMain}>
      {breathers.map((item) => (
        <Breather item={item} />
      ))}
    </div>
  );
}

function Breather({ item }) {
  const [isLoading, setIsLoading] = useState(false);
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);
  const {
    backgroundImage,
    title,
    theme,
    logo,
    buttonDetails,
    navigation,
    pictorialDisclaimerLogo,
    pictorialDisclaimerBgColor,
    textDisclaimer,
    buttonAnalyticsId,
    campaignAnalyticsId,
    subCampaignAnalyticsId,
    tags,
  } = breatherProxy(item);

  useEffect(() => {
    if (campaignAnalyticsId && subCampaignAnalyticsId) {
      const onPageLoadFunction = () =>
        messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
          // analyticEvent: {
          //   campaignAnalyticsId,
          //   subCampaignAnalyticsId,
          //   buttonHref: buttonDetails?.link,
          // },
          analyticEvent: `onBreatherLoad-${campaignAnalyticsId}-${subCampaignAnalyticsId}`,
        });
      setTimeout(onPageLoadFunction, 4000);
    }
  }, [campaignAnalyticsId, subCampaignAnalyticsId, buttonDetails?.link]);

  useEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      setButtonWidth(width);
    }
  }, []);

  async function hanldeButtonClick() {
    messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
      // analyticEvent: {
      //   campaignId: campaignAnalyticsId,
      //   subCampaignId: subCampaignAnalyticsId,
      //   link: buttonDetails?.link,
      //   clickedElement: buttonAnalyticsId,
      // },
      analyticEvent: `onBreatherClick-${campaignAnalyticsId}-${subCampaignAnalyticsId}`,
    });

    if (buttonDetails?.isSSOEnabled) {
      setIsLoading(true);
      const redirectUrl = await ssoFunction(buttonDetails.link);
      setIsLoading(false);
      messageToNative(betCcbEvents.DAZN_BET, {
        isExternal: navigation === "_blank" ? true : false,
        url: redirectUrl,
      });
    } else {
      messageToNative(betCcbEvents.DAZN_BET, {
        isExternal: navigation === "_blank" ? true : false,
        url: buttonDetails.link,
        feature: tags.reduce(
          (acc, cur) => (acc ? acc + "," + cur : acc + cur),
          ""
        ),
      });
    }
  }

  return (
    <div className={styles.main}>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        className={styles.container}
      >
        {title && (
          <h1
            className={classNames(styles.title, {
              [styles.darkModeTitle]: theme === ThemeTypes.DARK,
              [styles.dynamicTitle]: pictorialDisclaimerLogo,
            })}
          >
            {title}
          </h1>
        )}

        <section className={styles.rightSection}>
          {logo && <img className={styles.logo} src={logo} alt="logo" />}
          {buttonDetails && (
            <div
              className={classNames(styles.button, {
                [styles.buttonDarkMode]: theme === ThemeTypes.DARK,
              })}
              onClick={hanldeButtonClick}
              ref={buttonRef}
              style={{
                width: buttonWidth ? `${buttonWidth}px` : "auto",
                backgroundColor: buttonDetails.color,
              }}
            >
              {isLoading ? (
                <Spin
                  width={24}
                  height={24}
                  color={theme === ThemeTypes.DARK ? "black" : "white"}
                />
              ) : (
                buttonDetails.betButtonLabel
              )}
            </div>
          )}
          {pictorialDisclaimerLogo && (
            <img
              style={{
                backgroundColor: pictorialDisclaimerBgColor,
              }}
              className={styles.pictorialDisclaimerLogo}
              src={pictorialDisclaimerLogo}
              alt="pictorialDisclaimerLogo"
            />
          )}
        </section>
      </div>
      {textDisclaimer && (
        <MarkdownContent className={styles.textDisclaimer} component={"p"}>
          {textDisclaimer}
        </MarkdownContent>
      )}
    </div>
  );
}

export default SlimBreathers;
