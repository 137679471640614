const capitalize = (word) =>
  `${word.slice(0, 1).toLocaleUpperCase()}${word.slice(1).toLocaleLowerCase()}`;

const lowerFirst = (word) =>
  `${word.slice(0, 1).toLocaleLowerCase()}${word.slice(1)}`;

const capitalizeFirstLetter = (word) =>
  word?.charAt(0)?.toUpperCase() + word?.slice(1);

export { capitalize, lowerFirst, capitalizeFirstLetter };
