import classNames from "classnames";
import React, { useState } from "react";
import styles from "./styles/PlacedBets.module.css";
import { AccordianIcon } from "./reusable/svgUtils";
import { StatusText } from "./reusable/Status";
import {
  betStatusConstants,
  notSettledStatues,
} from "../../constants/betslip.constants";
import localisePrice from "../../utils/formatCurrency";
import { getLocale } from "../../utils/common.utils";

function PlacedBets({
  betStore,
  contentfulStrings,
  showFullDetails,
  setShowFullDetails,
}) {
  const {
    userBetDetails: { totalPotentialReturns, betHistory, totalReturns },
    betDetailsRes: { currency },
  } = betStore;
  const locale = getLocale();

  if (!betHistory?.length) return null;

  const handleAccordianClick = () => {
    setShowFullDetails(!showFullDetails);
  };

  return (
    <div
      className={classNames({
        [styles.body]: true,
        [styles.dynamicStylesBody]: showFullDetails,
      })}
    >
      <div
        className={classNames({
          [styles.headerDiv]: true,
          [styles.removeBottomBorder]: showFullDetails,
        })}
        onClick={handleAccordianClick}
      >
        <div className={styles.leftSection}>
          <p className={styles.noOfBets}>{betHistory.length}</p>
          <p className={styles.myBetsTitle}>{contentfulStrings("myBets")}</p>
        </div>
        <div className={styles.rightSection}>
          {!showFullDetails && (
            <p className={styles.toReturn}>
              {/* partial bets */}
              {Number(totalPotentialReturns) > 0 && Number(totalReturns) > 0 ? (
                <>
                  {contentfulStrings("toReturn")}
                  <span className={styles.totalReturns}>
                    {localisePrice(
                      Number(totalPotentialReturns) + Number(totalReturns),
                      currency,
                      locale
                    )}
                  </span>
                </>
              ) : Number(totalPotentialReturns) === 0 ? (
                <>
                  {contentfulStrings("returnsText")}
                  <span className={styles.totalReturns}>
                    {localisePrice(Number(totalReturns), currency, locale)}
                  </span>
                </>
              ) : (
                <>
                  {contentfulStrings("toReturn")}
                  <span className={styles.totalReturns}>
                    {localisePrice(
                      Number(totalPotentialReturns),
                      currency,
                      locale
                    )}
                  </span>
                </>
              )}
            </p>
          )}
          <AccordianIcon type={showFullDetails ? "up" : "down"} />
        </div>
      </div>
      {showFullDetails && (
        <div className={styles.detailsDiv}>
          {betHistory.map((placedBetDetails, i) => {
            let { betStatus } = placedBetDetails;
            betStatus = betStatus.toLowerCase();
            // const isPlaceBet = placedBetDetails?.betType
            //   ?.toLowerCase()
            //   ?.includes("freebet");
            return (
              <div key={i}>
                <div className={styles.statusDiv}>
                  <div className={styles.leftSectionStatusDiv}>
                    <div className={styles.betAmount}>
                      {localisePrice(
                        Number(placedBetDetails.betAmount),
                        currency,
                        locale
                      )}
                    </div>

                    {notSettledStatues.includes(betStatus.toLowerCase()) ? (
                      <p className={styles.returnsText}>
                        {contentfulStrings("toReturn")}
                        <span
                          className={classNames({
                            [styles.textInsideReturns]: true,
                          })}
                        >
                          {localisePrice(
                            Number(placedBetDetails.potentailReturns),
                            currency,
                            locale
                          )}
                        </span>
                      </p>
                    ) : (
                      <p className={styles.returnsText}>
                        {contentfulStrings("returnsText")}
                        <span
                          className={classNames({
                            [styles.textInsideReturns]: true,
                            [styles.wonColor]:
                              betStatus.toLowerCase() ===
                              betStatusConstants.WON,
                            [styles.lostColor]:
                              betStatus.toLowerCase() ===
                              betStatusConstants.LOST,
                          })}
                        >
                          {localisePrice(
                            Number(placedBetDetails.totalReturns),
                            currency,
                            locale
                          )}
                        </span>
                      </p>
                    )}
                  </div>
                  {/* 
                  {isPlaceBet && (
                    <p className={styles.freeBetText}>
                      {contentfulStrings("freeBetInHistory")}
                    </p>
                  )} */}
                  <StatusText
                    status={placedBetDetails?.betStatus?.toLowerCase()}
                    contentfulStrings={contentfulStrings}
                  />
                </div>
                <div className={styles.listOfQuestions}>
                  {placedBetDetails?.options?.map((question, index) => {
                    return (
                      <div className={styles.singleQuestion} key={index}>
                        <div className={styles.svgLineDiv}>
                          <div className={styles.svgLineTop}></div>
                          <div className={styles.questionIcon}></div>
                          <div className={styles.svgLineBottom}></div>
                        </div>
                        <p className={styles.question}> {question}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default PlacedBets;
