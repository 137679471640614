const getImageObj = imageobj => {
    if (imageobj.fields?.file) {
        return {
            src: imageobj.fields?.file?.url,
        };
    }
    const image = {
        default: imageobj?.fields?.default?.fields?.file?.url,
        web: imageobj?.fields?.web?.fields?.file.url,
        mobile: imageobj?.fields?.mobile?.fields?.file?.url,
        tablet: imageobj?.fields?.tablet?.fields?.file?.url,
        livingRoom: imageobj?.fields?.livingRoom?.fields?.file?.url,
    };

    return {
        ...image,
        src: image.default,
        srcSet: [image.mobile, image.tablet, image.web],
    };
};

const transformOdd = odd => {
    const result = odd?.fields;
    return {
        ...result,
        logoImage: getImageObj(result.logoImage),
        button: {
            ...result?.button?.fields,
            image: getImageObj(result?.button?.fields?.image),
        },
    };
};

const oddsCheckerDataProxy = response => {
    const result = response?.fields;
    const trademarkImage = result?.trademarkImage
        ? getImageObj(result.trademarkImage)
        : null;
    const poweredByImage = result?.poweredByImage
        ? getImageObj(result.poweredByImage)
        : null;
    const odds = result?.odds?.map(odd => transformOdd(odd));
    return {
        ...result,
        tag: result.tags?.[0]?.split('_')?.[1],
        trademarkImage,
        poweredByImage,
        odds,
    };
};

export { oddsCheckerDataProxy };
