import React from "react";
import { BetLogo, QuestionMark } from "./reusable/svgUtils";
import classNames from "classnames";
import styles from "./styles/BetSlip.module.css";

function Header({ contentfulStrings, inside }) {
  return (
    <div className={classNames(styles.headerNew,{[styles.dynamicHeader] : inside })}>
      <BetLogo />
      <p className={classNames(styles.headerTitleNew)}>
        {contentfulStrings("headerQuestion")}
      </p>
      <QuestionMark contentfulStrings={contentfulStrings} />
    </div>
  );
}

export default Header;
