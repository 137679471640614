import { useState, useEffect } from 'react';
import { contentfulTypes } from '../constants/contentfulTypes.constants';
import { contentfulService } from './contentful.service';

const { COMMON_GROUP } = contentfulTypes;

export const useContentfulBetSlip = () => {
    const [contentfulData, setContentfulData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await contentfulService(COMMON_GROUP, {
                    'fields.tags[in]': 'BetSlip',
                });

                if (response !== null) {
                    setContentfulData(response?.items[0]?.fields);
                }
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

    }, []); // Empty dependency array ensures this effect runs only once

    return { isLoading, error, contentfulData };
};
