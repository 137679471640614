import { endpointUrls, methodType } from "../constants/api.constants";
import { betCcbEvents, countryCodes } from "../constants/betslip.constants";
import { TOKEN } from "../constants/constants";
import { messageToNative } from "../services/nativeApp.service";

const searchParams = new URLSearchParams(window?.location?.search);

// Retrieve a value from the local storage based on a specific key
function getValueFromLocalStorage(key) {
  return localStorage.getItem(key);
}
let jwtTokenGlobal = null;

const getAuthToken = () => {
  if (jwtTokenGlobal === null && getValueFromLocalStorage(TOKEN)) {
    return (jwtTokenGlobal = getValueFromLocalStorage(TOKEN));
  }
  return jwtTokenGlobal;
};

const getLocale = () => {
  let locale = searchParams?.get("locale");
  let langAndCountry = locale?.split("-");
  if (langAndCountry) {
    langAndCountry[1] = langAndCountry[1]?.toUpperCase();
  }

  locale = langAndCountry?.join("-");
  return locale;
};
const getLanguage = () => {
  const language = searchParams?.get("locale").split("-")[0];
  return language;
};
const getCountryCode = () => {
  const countryCode = searchParams?.get("locale")?.split("-")[1];
  return countryCode?.toUpperCase();
};

const isGermany = countryCodes.GERMANY === getCountryCode();

const getHomeCountryCurrency = () => "GBP";

const getKeyValue = (contentfulData, key) =>
  contentfulData?.keyValueEntries?.find((item) => item?.fields?.key === key)
    ?.fields?.value || "";

const getContentfulImage = (contentfulData, imageKey) =>
  contentfulData?.entries?.find((item) => item?.fields?.key === imageKey)
    ?.fields?.default?.fields?.file?.url;

const getContentfulComponent = (contentfulData, componentKey) => {
  let components = [];
  contentfulData?.entries?.find((item) => {
    if (item?.fields?.tags?.includes(componentKey)) {
      components.push(item);
    }
  });
  return components;
};

const isDecimal = (value) => {
  return !Number.isInteger(value);
};

const decimalToFraction = (decimal) => {
  // Multiply by a power of 10 to convert the decimal to an integer
  let numerator = decimal * 1000;
  let denominator = 1000;

  // Find the greatest common divisor (GCD) to simplify the fraction
  const gcd = (a, b) => (b ? gcd(b, a % b) : a);
  const divisor = gcd(numerator, denominator);

  // Simplify the fraction
  numerator /= divisor;
  denominator /= divisor;

  return `${numerator}/${denominator}`;
};

const handleMarkdownLink = (event) => {
  event.preventDefault();
  if (event.target.tagName === "A") {
    messageToNative(betCcbEvents.BET_NAVIGATIION, {
      url: event.target.href,
      isExternal: event.target.target === "_self" ? false : true,
    });
  }
};

const showSectionIfText = (text) => text !== "" && text !== "-empty-";

const fetchFixtueDetails = async (fixtureId) => {
  let newUrl = new URL(endpointUrls.EVENT);
  newUrl.searchParams.append("id", fixtureId);
  newUrl.searchParams.append("country", getCountryCode()?.toLowerCase());
  newUrl.searchParams.append("languageCode", getLanguage());
  newUrl.searchParams.append("openBrowse", false);

  return fetch(newUrl.href, {
    method: methodType.GET,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      }

      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export {
  getAuthToken,
  getLocale,
  getLanguage,
  getKeyValue,
  getContentfulImage,
  getHomeCountryCurrency,
  isDecimal,
  decimalToFraction,
  handleMarkdownLink,
  getCountryCode,
  showSectionIfText,
  fetchFixtueDetails,
  getContentfulComponent,
  isGermany,
};
