import { getAuthToken } from "../utils/common.utils";

window.daznNativeBridge = window.daznNativeBridge || {};

export function createOverlay(event, message) {
  // Create overlay div
  var ccbEvent = {
    eventName: event,
    data: message,
    jwtToken: getAuthToken(),
  };
  const overlayDiv = document.createElement("div");
  overlayDiv.style.position = "fixed";
  overlayDiv.style.top = "0";
  overlayDiv.style.left = "0";
  overlayDiv.style.width = "100%";
  overlayDiv.style.height = "100%";
  overlayDiv.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  overlayDiv.style.zIndex = "9999";
  overlayDiv.innerText = JSON.stringify(ccbEvent); // You can customize this message

  // Append overlay div to the document body
  document.body.appendChild(overlayDiv);

  // After 15 seconds, remove the overlay div
  setTimeout(() => {
    overlayDiv.remove();
  }, 5000);
}

function messageToNative(eventName, data) {
  window.daznNativeBridge = window.daznNativeBridge || {};
  console.log(
    `CCB SENT(web check)::: with message name as ${eventName} and payload as ${JSON.stringify(
      data
    )}`
  );
  console.log({ data });

  if (window.daznNativeBridge.messageToNative) {
    window.daznNativeBridge.messageToNative(eventName, data);
    console.log(" Sent Real ccb Event with navtive messageToNative function ");
  }
}

export { messageToNative };
