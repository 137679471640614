const contentfulTypes = {
    COMMON_GROUP: 'CommonGroup',
    CATALOGUE_BET_COMPONENTS_ORDER: 'CatalogueBetComponentsOrder',
    ADAPTIVE_IMAGE: 'AdaptiveImage',
    BET_SLIP: 'CatalogueBetSlip',
};

const contentfulSubTypes = {
    BET_NEWS_FEED_TILE: 'betNewsFeedTiles',
    BET_SAFERGAMBLING_TILE: 'betSaferGamblingTiles',
    CATALOGUE_DYNAMIC_RAILS: 'CatalogueDynamicRails',
};

const contentfulHostTypes = {
    CDN: 'cdn',
    PREVIEW: 'preview',
};

const contentfulEnvTypes = {
    STAGE: 'stage',
    MASTER: 'master',
};

export { contentfulEnvTypes, contentfulHostTypes, contentfulSubTypes, contentfulTypes };
