import xss from 'xss';

const MARKED_OPTIONS = {
    breaks: true,
    mangle: true,
    xhtml: true,
    sanitize: false,
};

const XSS_OPTIONS = {
    whiteList: xss.whiteList,
};

export { MARKED_OPTIONS, XSS_OPTIONS };
