const betErrorTypes = {
  API_ERROR: "API_ERROR",
  INELIGIBLE: "INELIGIBLE",
  NO_LONGER_AVAILABLE: "NOT_LONGER_AVAILABLE",
};

const errorMessages = {
  geoLocationError: "Country Not Allowed",
};

const betCcbEvents = {
  INELIGIBLE_TO_BET: "ineligible_to_bet",
  FIXTURE_STARTED: "fixture_started",
  REGISTER: "register_and_place_bet",
  LOGIN: "login_and_place_bet",
  DEPOSIT: "deposit_button",
  MORE_BETS: "more_bets",
  FAQS: "betslip_faqs",
  DAZN_BET: "dazn_bet",
  BET_NAVIGATIION: "betNavigation",
  PLACE_BET: "place_bet",
  BETSLIP_ANALYTICS: "betslip_analytics",
  HIDE_BETSLIP_TAB: "hide_betslip_tab",
};

const betSlipActions = {
  INELIGIBLE: "ineligible-to-bet",
  LOGIN: "login-placebet",
  PLACE_BET: "placebet",
  REGISTER: "register-deposit-placebet",
  BET_CLOSED: "betting-closed",
  BETTING_NOT_STARTED: "betting-not-started",
};

const betStatusConstants = {
  OPEN: "open",
  LIVE: "live",
  WON: "won",
  LOST: "lost",
  VOID: "void",
  COMPLETED: "completed",
  IN_PROGRESS: "inprogress",
};

const countryCodes = {
  ITALY: "IT",
  GERMANY: "DE",
  UK: "UK",
};

// const betSettledStatuses = ["won", "lost", "void"];
const notSettledStatues = ["open", "live", "completed"];

const maxBetInput = 1000000;

const loadingText = {
  en: "LOADING...",
  "en-es": "LOADING...",
  "es-es": "CARGANDO...",
  "de-de": "Laden...",
};

export {
  betErrorTypes,
  betCcbEvents,
  betSlipActions,
  betStatusConstants,
  maxBetInput,
  notSettledStatues,
  loadingText,
  countryCodes,
  errorMessages,
};
