import { getEnv } from "../utils/betslip.config";
import { PROD } from "./constants";

export const methodType = {
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const stagEndPoints = {
  OAUTH: "https://myaccount-bff.ar.dazn-stage.com/v2/oauth",
  PARTNER_OUTBOUND: "https://sports-betting-service.picks.dazn-stage.com/v1/",
  EVENT: "https://event.discovery.dazn-stage.com/eu/v7/Event",
};

const prodEndPoints = {
  OAUTH: "https://myaccount-bff.ar.indazn.com/v2/oauth",
  PARTNER_OUTBOUND: "https://sports-betting-service.picks.indazn.com/v1/",
  EVENT: "https://event.discovery.indazn.com/eu/v5/Event",
};

const endpointUrls = getEnv() === PROD ? prodEndPoints : stagEndPoints;

export { endpointUrls };
