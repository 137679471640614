import { createClient } from 'contentful';
import { getLanguage, getLocale } from '../utils/common.utils';
import { getEnv } from '../utils/betslip.config';
import { PROD } from '../constants/constants';

const locale = getLocale();

const contentFulConfigurations = {
    CONTENTFUL_SPACE_ID: 'vhp9jnid12wf',
    CONTENTFUL_ENVIRONMENT: getEnv() === PROD ? 'master' :'stage',
    CONTENTFUL_ACCESS_TOKEN: '_wVOzTvLsLEr_U4gOBns9jeDpV_zlTL0DJBMITAtgbE',
    CONTENTFUL_HOST: 'cdn.contentful.com',
};

const space = contentFulConfigurations.CONTENTFUL_SPACE_ID;
const host = contentFulConfigurations.CONTENTFUL_HOST;
const environment = contentFulConfigurations.CONTENTFUL_ENVIRONMENT;
const accessToken = contentFulConfigurations.CONTENTFUL_ACCESS_TOKEN;

const client = createClient({
    space,
    host,
    environment,
    accessToken,
});

const excludedLocales = ['es-US', 'es-MX', 'es-ES', 'fr-BE', 'fr-CA', 'fr-FR'];

function getContentfulLocale(locale) {
    if (getLanguage().toLowerCase() === 'es' && !excludedLocales.includes(locale)) {
        return 'es-LA';
    } else if (getLanguage().toLowerCase() === 'ar') {
        return 'ar-SA';
    } else if (getLanguage().toLowerCase() === 'he') {
        return 'he-IL';
    } else if (
        getLanguage().toLowerCase() === 'fr' &&
        !excludedLocales.includes(locale)
    ) {
        return 'fr-ZA';
    }

    return locale;
}

const contentfulService = async (contentType, params = {}) => {
    const contentfulLocale = getContentfulLocale(locale);
    return client.getEntries({
        // eslint-disable-next-line
        content_type: contentType,
        locale: contentfulLocale,
        include: 10,
        ...params,
    });
};

export { contentfulService, getContentfulLocale };
