const AUTH = 'auth';
const TOKEN = 'MISL.authToken';
const STAG = 'stage';
const PROD = 'prod';

export {
    AUTH,
    TOKEN,
    PROD,
    STAG
}