const localisePrice = (price, currency, locale = "en-GB") => {
  // if (!price) {
  //   return;
  // }

  const formattedPrice = price.toLocaleString(locale, {
    style: "currency",
    currency: currency || "eur",
  });

  if (locale === "ja-JP") {
    return `${formattedPrice}円`.replace(/(￥|¥)/, "").trim();
  }

  if (locale !== "en-GB") {
    let currencySymbol = formattedPrice.match(/[^0-9.,]+/g)[0];
    let numericalValue = formattedPrice.replace(currencySymbol, "").trim();
    return numericalValue + currencySymbol;
  }

  return formattedPrice;
};

export default localisePrice;
