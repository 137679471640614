import React, { useEffect } from "react";
import styles from "./styles/BetNotStarted.module.css";
import { messageToNative } from "../../services/nativeApp.service";
import { betCcbEvents } from "../../constants/betslip.constants";
import { CountDown } from "../CountDown/CountDown";

function BettingNotStarted({ betStore, contentfulStrings }) {
  const {
    betDetailsRes: { betStartTime },
  } = betStore;

  useEffect(() => {
    const hideBetSlipTab = () => {
      messageToNative(betCcbEvents.HIDE_BETSLIP_TAB, {});
    };
    if (contentfulStrings("isAutoCloseBetTab") === "true") {
      setTimeout(hideBetSlipTab, 10000);
    }
  }, []);
  return (
    <div className={styles.main}>
      <h1 className={styles.notStartedText}>
        {contentfulStrings("bettingNotStarted")}
      </h1>
      {betStartTime && (
        <CountDown
          startTime={betStartTime * 10 ** 3}
          contentfulStrings={contentfulStrings}
        />
      )}
    </div>
  );
}

export default BettingNotStarted;
