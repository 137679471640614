import { types } from "mobx-state-tree";
import { endpointUrls, methodType } from "../constants/api.constants";
import { addArticleIdToApiUrl, partnerServices } from "../utils/betslip.config";
import { getAuthToken, getLocale, isGermany } from "../utils/common.utils";
import { betSlipConfigs, getCurrencySymbol } from "../utils/betslip.utils";
import { messageToNative } from "../services/nativeApp.service";
import { betCcbEvents, betSlipActions } from "../constants/betslip.constants";

const { fixtureId } = betSlipConfigs;

const Question = types.model("Question", {
  id: types.union(types.number, types.string), // mostly number
  selection: types.optional(types.frozen({}), {}),
});
const locale = getLocale();

const BetStore = types
  .model("Bet", {
    answers: types.array(Question),
    betDetailsRes: types.optional(types.frozen({}), {}),
    userBetDetails: types.optional(types.frozen({}), {}),
    potentialReturnsResponse: types.optional(types.frozen({}), null),
    userBetAmountInput: types.optional(
      types.union(types.number, types.string),
      ""
    ),
    isDepositInputFocused: types.optional(types.boolean, false),
    isApiError: types.optional(types.boolean, false),
    userBalance: types.optional(types.frozen({}), {}),
    isMoreBetsEnabled: types.optional(types.boolean, false),
    isButtonLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    isQuestionAnswered(questionId) {
      return self.answers.some(
        (answer) => answer.id === questionId && answer.selection.id
      );
    },
    isOptionAnswered(questionId, optionId) {
      return self.answers.some(
        (ans) => ans.id === questionId && ans.selection.id === optionId
      );
    },
    get callPartnerOutboundIntegrationService() {
      return partnerServices["stage"]; //Handle Per Env
    },
    get authToken() {
      return getAuthToken();
    },
  }))
  .actions((self) => ({
    addAnswer(data) {
      const questionId = data.id;
      const optionId = data.selection.id;
      if (self.isQuestionAnswered(questionId)) {
        if (self.isOptionAnswered(questionId, optionId)) {
          return self.deleteAnswer(questionId);
        }
        self.deleteAnswer(questionId);
      }
      self.answers.push(data);
    },
    deleteAnswer(questionId) {
      self.answers = self.answers.filter((answer) => answer.id !== questionId);
    },
    resetAnswers() {
      self.answers = [];
    },
    setUserBetAmountInput(amount) {
      self.userBetAmountInput = amount;
    },
    setDepositInputFocus(isFocused) {
      self.isDepositInputFocused = isFocused;
    },
    setIsApiError(isError) {
      self.isApiError = isError;
    },
    setMoreBets(isEnabled) {
      self.isMoreBetsEnabled = isEnabled;
    },
    setIsButtonLoading(isLoading) {
      self.isButtonLoading = isLoading;
    },
    getBetDetails(queryParams) {
      const url = `${endpointUrls.PARTNER_OUTBOUND}getBetDetails`;
      const urlWithArticleId = addArticleIdToApiUrl(url);
      return new Promise((resolve, reject) => {
        fetch(urlWithArticleId, {
          method: methodType.GET,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json().then((data) => {
              return { ...data, status: response.status };
            });
          })
          .then((res) => {
            if (res.status === 200) {
              messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
                analyticEvent: "onPageLoad",
              });
              self.updateData(false, "isApiError");
            } else {
              messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
                analyticEvent: `apiError-${res.status}`,
              });
              self.updateData(true, "isApiError");
            }
            const currency = res.currency ? res.currency : "GBP";
            const currencySymbol = getCurrencySymbol(currency, locale);
            self.updateData({ ...res, currencySymbol }, "betDetailsRes");
            if (res?.nextAction === betSlipActions.BET_CLOSED && isGermany) {
              messageToNative(betCcbEvents.HIDE_BETSLIP_TAB, {});
            }
            resolve(res);
          })
          .catch((error) => {
            self.updateData({ ...error?.response }, "betDetailsRes");
            self.updateData(true, "isApiError");
            reject(error);
          });
      });
    },
    updateData(res, assignTo) {
      self[assignTo] = res;
    },
    calculateUserPotentialReturns(payload) {
      const potentialReturnsApi = `${endpointUrls.PARTNER_OUTBOUND}calculateUserPotentialReturns`;
      const newUrl = addArticleIdToApiUrl(potentialReturnsApi);
      return new Promise((resolve, reject) => {
        fetch(newUrl, {
          method: methodType.POST,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((res) => {
            self.updateData(res, "potentialReturnsResponse");
            messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
              analyticEvent: "potentialReturns",
            });
            resolve(res);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    generatePlayerAccessToken() {
      return new Promise((resolve, reject) => {
        fetch(`${endpointUrls.PARTNER_OUTBOUND}generatePlayerAccessToken`, {
          method: methodType.POST,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((res) => resolve(res))
          .catch((error) => {
            self.updateData(true, "isApiError");
            reject(error);
          });
      });
    },
    postPlaceBet(payload) {
      return new Promise((resolve, reject) => {
        fetch(`${endpointUrls.PARTNER_OUTBOUND}placeBet/daznbet/${fixtureId}`, {
          method: methodType.POST,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            self.updateData(true, "isApiError");
            reject(error);
          });
      });
    },
    async resetBetSlip(payload = {}) {
      const res = await self.getBetDetails(payload);
      self.setIsButtonLoading(false);
    },
    getUserBetDetails() {
      const url = `${endpointUrls.PARTNER_OUTBOUND}getUserBetDetails`;
      const urlWithArticleId = addArticleIdToApiUrl(url);

      return new Promise((resolve, reject) => {
        fetch(urlWithArticleId, {
          method: methodType.GET,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((res) => {
            self.updateData(res, "userBetDetails");
            if (res.betHistory?.length > 0) {
              messageToNative(betCcbEvents.BETSLIP_ANALYTICS, {
                analyticEvent: "hasBetHistory",
              });
            }
            resolve(res);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
  }));

export default BetStore;
