import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked';
import classnames from 'classnames';
import xss from 'xss';

import style from './markdown-content.css';
import { MARKED_OPTIONS, XSS_OPTIONS } from './markdownContent.constants';
import { replaceStringWithPattern } from './replaceStringWithPattern';
import { lowerFirst } from '../../utils/textTransforms';
import { handleMarkdownLink } from '../../utils/common.utils';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
    if (href.includes('mailto:') && href.split(':')[1] === text) {
        return text;
    }
    return `<a href="${href}"${title ? ` title="${title}"` : ''}>${text}</a>`;
};

const options = {
    renderer,
    ...MARKED_OPTIONS,
};

const getLinkMappings = () => {
    const { links } = {};

    if (!links) {
        return {};
    }

    return Object.keys(links)?.reduce((obj = {}, key) => {
        obj[lowerFirst(key)] = links[key];
        obj[key] = links[key];
        return obj;
    }, {});
};

function renderMarkdown(markdown, inline = false, templateValues = {}) {
    if (!markdown) return '';
    const context = {
        ...getLinkMappings(),
        ...templateValues,
    };

    const withTemplateValues = replaceStringWithPattern(context, markdown);

    const rendered = inline
        ? marked.parseInline(withTemplateValues.replace('\n', ' '), [], options)
        : marked(withTemplateValues, options);

    return xss(rendered.replace(/\n/g, ''), XSS_OPTIONS);
}

MarkdownContent.propTypes = {
    children: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    className: PropTypes.string,
    inline: PropTypes.bool,
    templateValues: PropTypes.object,
    'data-test-id': PropTypes.string,
};

MarkdownContent.defaultProps = {
    component: 'div',
    className: null,
    inline: false,
    templateValues: {},
    'data-test-id': null,
};

function MarkdownContent({
    children,
    className,
    inline,
    templateValues,
    component: Component,
    ['data-test-id']: dataTestId,
}) {
    const dangerousHtml = {
        __html: renderMarkdown(children, inline, templateValues),
    };

    return (
        <Component
            data-test-id={dataTestId}
            className={classnames(style.markdownContent, className)}
            dangerouslySetInnerHTML={dangerousHtml}
            onClick={handleMarkdownLink}
        />
    );
}

export { MarkdownContent as default, getLinkMappings };
