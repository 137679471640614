import React from "react";
import PropTypes from "prop-types";

import styles from "../styles/PlacedBets.module.css";
import { betStatusConstants } from "../../../constants/betslip.constants";
import classNames from "classnames";

function StatusIcon({ status }) {
  switch (status) {
    case "won":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6C0 2.68629 2.68629 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM2.95312 6.518L5.05312 9.104L9.07912 4.268L8.16113 3.5L5.06512 7.214L3.88912 5.762L2.95312 6.518Z"
          fill="#85EBA5"
        />
      );
    case "lost":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM8.40156 7.55556L7.55556 8.40156L6.00156 6.84756L4.44756 8.40156L3.60156 7.55556L5.15556 6.00156L3.60156 4.44756L4.44756 3.60156L6.00156 5.15556L7.55556 3.60156L8.40156 4.44756L6.84756 6.00156L8.40156 7.55556Z"
          fill="#FD7777"
        />
      );
    case "void":
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM8.40156 7.55556L7.55556 8.40156L6.00156 6.84756L4.44756 8.40156L3.60156 7.55556L5.15556 6.00156L3.60156 4.44756L4.44756 3.60156L6.00156 5.15556L7.55556 3.60156L8.40156 4.44756L6.84756 6.00156L8.40156 7.55556Z"
          fill="#0C161C"
        />
      );
    // default one is in progress
    default:
      return (
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="6"
          cy="6"
          r="6"
          fill="#D9D9D9"
        />
      );
  }
}

function StatusText({ status, contentfulStrings }) {
  switch (status) {
    case betStatusConstants.OPEN:
      return (
        <p className={styles.statusText} style={{ color: "#B2B2B2" }}>
          {contentfulStrings("betStatusOpen")}
        </p>
      );
    case betStatusConstants.COMPLETED:
    case betStatusConstants.LIVE:
      return (
        <p
          className={styles.liveText}
          style={{ background: "#D12424", color: "#F9FAFA" }}
        >
          {contentfulStrings("betStatusLive")}
        </p>
      );
    case betStatusConstants.WON:
      return (
        <p className={styles.statusText} style={{ color: "#85EBA5" }}>
          {contentfulStrings("betStatusWon")}
        </p>
      );
    case betStatusConstants.LOST:
      return (
        <p className={styles.statusText} style={{ color: "#FD7777" }}>
          {contentfulStrings("betStatusLost")}
        </p>
      );
    case betStatusConstants.VOID:
      return (
        <p className={styles.statusText} style={{ color: "#F9FAFA" }}>
          {contentfulStrings("betStatusVoid")}
        </p>
      );
    // default one is in progress
    default:
      return (
        <p
          className={styles.statusText}
          style={{ background: "rgba(249, 250, 250, 0.4)" }}
        >
          {contentfulStrings("betStatusInprogress")
            ? contentfulStrings("betStatusInprogress")
            : "IN PROGRESS"}
        </p>
      );
  }
}

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};
StatusText.propTypes = {
  status: PropTypes.string.isRequired,
  contentfulStrings: PropTypes.func.isRequired,
};

export { StatusIcon, StatusText };
