import React from "react";
import { observer, inject } from "mobx-react";
import { BetSlipForm } from "./BetSlip/BetSlipComponent";
import { getSnapshot } from "mobx-state-tree";
import { getCountryCode } from "../utils/common.utils";
import { countryCodes } from "../constants/betslip.constants";
import { OddsChecker } from "./OddsChecker/OddsChecker";

const AppComponent = ({ betStore }) => {
  const answers = getSnapshot(betStore);

  const countryCode = getCountryCode();

  if (countryCodes.ITALY === countryCode) {
    return <OddsChecker />;
  }
  return <BetSlipForm betStore={betStore} />;
};

export default inject("betStore")(observer(AppComponent));
